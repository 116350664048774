import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "NestJS を使ってジョブメドレーアカデミーのバックエンド開発をどのように行なっているのか",
  "date": "2022-11-30T01:40:06.000Z",
  "slug": "entry/2022/11/30/104006",
  "tags": ["medley"],
  "hero": "./2022_11_30.png",
  "heroAlt": "Title"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`みなさん、こんにちは。エンジニアの山田です。今回はジョブメドレーアカデミー（以下、アカデミー）の開発の皆さんに集まってもらい、`}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/"
      }}>{`TypeScript`}</a>{` と `}<a parentName="p" {...{
        "href": "https://nestjs.com/"
      }}>{`NestJS`}</a>{` を使ったバックエンド開発をどのように行なっているのかをインタビューしました。`}</p>
    <p>{`以前、アカデミーがリニューアルした際にチームメンバーにインタビューした `}<a parentName="p" {...{
        "href": "https://note.com/medley/n/na0d591fe3f65"
      }}>{`note`}</a>{` もあるので、未読の方はぜひそちらもご覧いただければと思います。`}</p>
    <h1>{`インタビュイー紹介`}</h1>
    <h2>{`岸田さん`}</h2>
    <p>{`SES 会社で業務システム開発、バングラデシュに支社があるオフショア開発をしている会社でブリッジエンジニアという経験を経て、2019 年メドレー入社。 その後ジョブメドレーで開発をリード。現在はアカデミーのプロダクト責任者を務める。`}</p>
    <h2>{`熊本さん`}</h2>
    <p>{`2019 年メドレーに新卒入社。半年の研修を経てジョブメドレーの開発に携わる。現在はアカデミーの開発に携わる。大学では機械学習を用いた自動車の自動運転の研究をしていた。`}</p>
    <h2>{`牧野さん`}</h2>
    <p>{`2021 年 11 月にメドレー入社。不動産業界の SaaS を開発している会社でのマネージャー・エンジニアを経験。現在はアカデミーの開発に携わる。`}</p>
    <h2>{`徳永さん`}</h2>
    <p>{`2022 年メドレーに新卒入社。 OJT 期間中からアカデミーの開発に携わる。大学では、対話 AI システム・ AI との対話用プラットフォームの研究開発をしていた。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f2d7ce0e33e6a315d7e81d449767f684/eea4a/note1104_001.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAACA//EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABR10whRbL/8QAHBAAAQMFAAAAAAAAAAAAAAAAAwIiMgABEhMx/9oACAEBAAEFAgreTZmllAlfgof/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAADAAIDAAAAAAAAAAAAAAAAAQIQESFRgf/aAAgBAQAGPwKpnZPQ07b5Lx6z/8QAHhAAAgEDBQAAAAAAAAAAAAAAAAERITFhQVFxofD/2gAIAQEAAT8hQ4DVveupFBfd5LwDlxg76K3k8GT/2gAMAwEAAgADAAAAEKff/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEh/9oACAEDAQE/EJqP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxC4Vo//xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhUcExYfD/2gAIAQEAAT8QBQTdVFIG3kT1lK1tNjwNfecgMo2bvhKi16cAECR04lpVezn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "photo1",
            "title": "photo1",
            "src": "/static/f2d7ce0e33e6a315d7e81d449767f684/e5166/note1104_001.jpg",
            "srcSet": ["/static/f2d7ce0e33e6a315d7e81d449767f684/f93b5/note1104_001.jpg 300w", "/static/f2d7ce0e33e6a315d7e81d449767f684/b4294/note1104_001.jpg 600w", "/static/f2d7ce0e33e6a315d7e81d449767f684/e5166/note1104_001.jpg 1200w", "/static/f2d7ce0e33e6a315d7e81d449767f684/eea4a/note1104_001.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`アカデミーのアーキテクチャ`}</h1>
    <p><strong parentName="p">{`山田`}</strong>{`: 早速ですが、現在のアカデミーのアーキテクチャについて話を伺いたいと思います。全体の構成としては現在どんな形になっているんでしょうか。`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 以前話をしたところから大幅に変わったところはないですが、大まかにはこんな感じになっています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e60f32f27f66332a2536a0e7ddf8c87f/30414/academy_arch.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "138.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAYAAABh2p9gAAAACXBIWXMAABYlAAAWJQFJUiTwAAAD7UlEQVRIx52Wu29cRRTG/S9Qp6Ogo6CEDiQK6KgjhIREgXCHhEIXYZFIRrwEoqAISoGJQ8CLEZalTRS/EkLsOBucVWyv4931rh/rfd33c2Z+aOZ6/d4YmNXsvedo7pnvO+ebc++A53kopZ45e0MpeebaAfqMXiB9sZplktA64u/3zMDBg6cji+OEcqmIa7f7rjuC8HDAfij72UeZnBGwZ9dXA+Z+tmhtyGeuOzOgFJk9NVbnwpuPWJry9/3/i3LPbtRd7kxu0N2NObYjKHlk/dk5lMd8CjqhRSOySHXBgEQmR2ifiXC3FlHIu1g7mW3JiF2rjPXwB9xaHocEoUR/2fTuezmczm3x8RtFCjc9QKAz2VqdY/PD53DG3sbVXpn0R9gLJvfollc65K4ss13JihIgaDbLFEaGqMz/godAyPRkwJO0JWkqjtVB4qU+jooIAI3ZET5Cyf4I7XZC4aZLq5YFq7cqTC9P0HDqxg5Vwpa3ze2VcR435vFVfDKH+tfLWWGmyeDLi8xcy87u6J9XeeXTc0ytju/hhr+q93jt8gt8fuuCqXZyKuW9y07NY+z7VcpF19iLpQW+yA2xslnMzjaCp50yw2OfMLn0OyGCVB5CqAMJKUhEeqp0DJskOyGxSPBFSERq9k9QRISanwG0377asU3dbxDICF/G2HGAJ+M9O8QXAalKqTpbZq32u8LHFQFVK6QdhaSkBwhDFeMKDxdFp3KP9uh5vJURbAR26uAknqHlqxBXBqayrvRpxz5TpYDFuk9KbHqnCah3tBPHyKBRmGDj4kt0Z4axUdixg5v6JvGOlkziGtuRmkVApdWganVJDiPUAbuJhy1C7LDL2tJd2p0artnIN7S1NFxiw8IhNig9GdK0OnQDm1glpr3vU04JSPDwCYjRwg0J8Im0R+nzAe2lVVrDN3CXSgRINqwNrtz9knzpVyMnJWVWFDsK+fGPXXKzLss1m6u5JovVgPu3G9wa6dJuBabqlZE8fz//Hq3crNFfofaQ1y+9yMXxDzKNSpEFbHQ93h18zEfDO0xOb/PW+SI/5S2ufbXMZ++UqK55exqt82TiDs3NLXximmGbfHGCB/X7CA699XwRk59dY+ZBjVrL4vp4keV6lycr28xNrdPxfCQSO7VpBjXstGuqrXPoBeDFgkjFRosmhzqhKREJWrQRitjkUf+n2iuzHLYWrtMaOoez8K1pDk/Xd/n6/TV++6aNIjXiH+j1Qd2u9NT3Qrevnk+/Q6Q0J2OnMMn65VdpPxolBNarDYYH57nxXQWFOAj4b4ZUMpOXb+OLiEDF+DKi5XSxQodUiUPd5j/M3tfEyc+UrCj/AMkSWNBTD+/VAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Architecture",
            "title": "Architecture",
            "src": "/static/e60f32f27f66332a2536a0e7ddf8c87f/c1b63/academy_arch.png",
            "srcSet": ["/static/e60f32f27f66332a2536a0e7ddf8c87f/5a46d/academy_arch.png 300w", "/static/e60f32f27f66332a2536a0e7ddf8c87f/0a47e/academy_arch.png 600w", "/static/e60f32f27f66332a2536a0e7ddf8c87f/c1b63/academy_arch.png 1200w", "/static/e60f32f27f66332a2536a0e7ddf8c87f/d61c2/academy_arch.png 1800w", "/static/e60f32f27f66332a2536a0e7ddf8c87f/97a96/academy_arch.png 2400w", "/static/e60f32f27f66332a2536a0e7ddf8c87f/30414/academy_arch.png 4672w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`山田`}</strong>{`: ありがとうございます、分かりやすいですね。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8de686de75216b0374db588de7b6a0be/eea4a/note1104_009.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABeuDrixMj/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAMBAhETMf/aAAgBAQABBQKl9i6pw8STw//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AWf/xAAZEAACAwEAAAAAAAAAAAAAAAABAwAQQQL/2gAIAQEABj8CX2chZlLF/wD/xAAcEAACAgIDAAAAAAAAAAAAAAABEQAxEEFRcYH/2gAIAQEAAT8hAKonowwwpCw7jlNHHst7x//aAAwDAQACAAMAAAAQ2z//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxAZ/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QE//EAB4QAQACAgEFAAAAAAAAAAAAAAEAESExkUFRYbHx/9oACAEBAAE/ELxrqNRmjmph2h1F1x7ibYnMcR7L8Ync/cXLP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "photo2",
            "title": "photo2",
            "src": "/static/8de686de75216b0374db588de7b6a0be/e5166/note1104_009.jpg",
            "srcSet": ["/static/8de686de75216b0374db588de7b6a0be/f93b5/note1104_009.jpg 300w", "/static/8de686de75216b0374db588de7b6a0be/b4294/note1104_009.jpg 600w", "/static/8de686de75216b0374db588de7b6a0be/e5166/note1104_009.jpg 1200w", "/static/8de686de75216b0374db588de7b6a0be/eea4a/note1104_009.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`(手前)岸田さん・(奥)牧野さん`}</em></p>
    <p><strong parentName="p">{`山田`}</strong>{`: Web フロントエンドに関しては `}<a parentName="p" {...{
        "href": "https://nextjs.org/"
      }}>{`Next.js`}</a>{` とグローバルの状態管理として `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/apollo-client/"
      }}>{`Apollo Client`}</a>{` を使っていると伺っています。バックエンドは色々あると思うのですが、DB に関しては `}<a parentName="p" {...{
        "href": "https://www.postgresql.org/"
      }}>{`PostgreSQL`}</a>{` を使っているんですよね?`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: はい、ORM としては `}<a parentName="p" {...{
        "href": "https://www.prisma.io/"
      }}>{`Prisma`}</a>{` を使っています。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: こうした開発環境はどのように決まったんでしょうか?特に、開発言語を全て TypeScript で統一する、という意思決定が先にあってバックエンド側のフレームワークは NestJS にしようとなったのか、逆に NestJS を使おうとして TypeScript になったのかをお聞きできれば。`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 細かいところまでは記憶にないのですが、TypeScript を使って型で開発をしたいというのが一番大きい理由でした。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: まずは TypeScript からだったんですね。そこから Web フレームワークとしては `}<a parentName="p" {...{
        "href": "https://expressjs.com/ja/"
      }}>{`Express`}</a>{` など他の物もあったと思いますが、NestJS を採用した理由は何だったのでしょうか?`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: そうですね、色々選択肢はあったと思うんですが、現実的にサービスを運用するとなると Express と NestJS のどちらかかな、という感じになっていました。`}</p>
    <p>{`どちらも実開発での経験を持っているメンバーはいなかったんですが、Web 開発で良く使われる Web フレームワークは、基本的な機能やコンセプトについては共通する部分が多いことに加えて、アカデミーも特殊な仕様があるプロダクトではないので、どちらを選んだとしてもメンバーが困ることはないだろうと考えました。`}</p>
    <p>{`その上で細かい使い勝手などを検討したり GraphQL との親和性の高さや Express という枯れた技術をベースにしていて、`}<strong parentName="p">{`フレームワーク自体の信頼感もあるので NestJS に決めた`}</strong>{`という感じでした。`}</p>
    <p><strong parentName="p">{`熊本`}</strong>{`: 絞り込んだ段階で各メンバーが NestJS を試験的に触り、使用感を見てみましたが、大きく問題になる点もなかったので全員一致で決まった感じですね。`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 前述のように Express をベースにしているので何か困ったら最悪 Express にすれば良いなというのもありました。`}</p>
    <p><strong parentName="p">{`牧野`}</strong>{`: ここまで実際大きな問題というのは無かったです。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: 今は `}<a parentName="p" {...{
        "href": "https://graphql.org/"
      }}>{`GraphQL`}</a>{` を使って API を作っていると思いますが、REST で作るという選択肢は最初から無かったんですか?`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: はい、GraphQL を使えるメンバーが揃っているという状況であえて REST にするメリットは無いなという事で考えていませんでした。外部公開する API があるとか、パフォーマンスがすごく重要な重い API 呼び出しというのがあったら別だったでしょうけども。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: そもそもの話になってしまうんですが、弊社のプロダクト開発でよく使われている `}<a parentName="p" {...{
        "href": "https://rubyonrails.org/"
      }}>{`Ruby on Rails`}</a>{` を使う選択肢は無かったんでしょうか?`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 選択肢としてはあるにはあったんですが、プロダクトのフェイズとして現在既に成長をしているサービスであることや、メンバーのスキルセットとして Rails に慣れたメンバーがそこまで多くなかったことを考えると、いかに技術的負債を少なく保ちつつ、機能開発をしていけるかという点が重要になるので、現在の技術構成にしました。`}</p>
    <p>{`これが既存サービスのリニューアルではなくて、新規で一から全部作っていかないといけないという状況であれば Rails を使うかもしれませんが、やっぱり型があってある程度開発効率が高くなるというのは魅力的だったので、`}<strong parentName="p">{`これからどんどんと機能開発をするには今の構成が良い`}</strong>{`と判断しました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/99662f1540b203e9d599700f21174e4c/eea4a/note1104_006.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABm5VSXRHFH//EABwQAAIBBQEAAAAAAAAAAAAAAAEDAAIEEiIyM//aAAgBAQABBQJycmqIKt5V3Z+s/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/Aax//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAES/9oACAECAQE/AY1X/8QAHxAAAgECBwAAAAAAAAAAAAAAAAIBAxAREiExcoGR/9oACAEBAAY/As8toNCtibR6VOJ1b//EAB8QAAEDAwUAAAAAAAAAAAAAAAEAESEQMWFxgbHB8P/aAAgBAQABPyEJSIA6J8xyU/v6Uxmzo2XMp//aAAwDAQACAAMAAAAQDA//xAAWEQEBAQAAAAAAAAAAAAAAAAAAETH/2gAIAQMBAT8QzEv/xAAWEQEBAQAAAAAAAAAAAAAAAAABECH/2gAIAQIBAT8Q2rL/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFRYXGB/9oACAEBAAE/ELjTQGsfPWLhJXG2hybbivH9Q1Euy1jCNUW03HepWX3P/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "photo3",
            "title": "photo3",
            "src": "/static/99662f1540b203e9d599700f21174e4c/e5166/note1104_006.jpg",
            "srcSet": ["/static/99662f1540b203e9d599700f21174e4c/f93b5/note1104_006.jpg 300w", "/static/99662f1540b203e9d599700f21174e4c/b4294/note1104_006.jpg 600w", "/static/99662f1540b203e9d599700f21174e4c/e5166/note1104_006.jpg 1200w", "/static/99662f1540b203e9d599700f21174e4c/eea4a/note1104_006.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`(手前)徳永さん・(奥)熊本さん`}</em></p>
    <p><strong parentName="p">{`山田`}</strong>{`: DB に関して PostgreSQL ですが、`}<a parentName="p" {...{
        "href": "https://www.mysql.com/jp/"
      }}>{`MySQL`}</a>{` などにするということは考えなかったですか?`}</p>
    <p><strong parentName="p">{`熊本`}</strong>{`: そうですね、リニューアル前のアカデミーが使っていたということがあり、大きく変えるメリットはあまり無かったのが大きいです。`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: あとこれは自分の感覚なのですが、PostgreSQL の方がスマートに処理できることが多いという印象があるというのもありました。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: 元々サービスで使っていたというのは、やっぱり大きいですね。ORM は Prisma ですよね。例えば他に `}<a parentName="p" {...{
        "href": "https://typeorm.io/"
      }}>{`TypeORM`}</a>{` などの選択肢もあったと思いますが、こちらはどういった経緯で使うことになりましたか?`}</p>
    <p><strong parentName="p">{`熊本`}</strong>{`: 色々と触ってみた結果、Prisma が`}<strong parentName="p">{`自分たちの使い方にマッチしてた`}</strong>{`のが大きかったです。がっつりと SQL を自動生成するなどの手厚い機能は、自分達の使い方では必要もなかったですし。キャッチアップのためにペアプロなどもしましたが、`}<strong parentName="p">{`メンバーも使いやすい`}</strong>{`ということが確認できました。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: 今ペアプロの話が出ましたが、どのような目的でやっていたんでしょうか。`}</p>
    <p><strong parentName="p">{`熊本`}</strong>{`: `}<strong parentName="p">{`初期の頃はメンバー間の知識などに偏りもあったので、そういったものを実際にコードを触りながら埋めていく`}</strong>{`という目的でした。実際に、GraphQL を使ったことがなかったメンバーも早く慣れてくれました。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: なるほど。ある種学習も兼ねていたんですね。最終的に現在このアーキテクチャの使い勝手としては感想としてどうでしょうか?`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 言葉は違うかもしれないですが「`}<strong parentName="p">{`普通に使い勝手が良い`}</strong>{`」という感じです。`}</p>
    <p>{`もちろんメンバーが習熟してきたので、色々とコード的に改善しようという部分はあったりもしますが、今のところ本当に困った問題というのは無いので、非常に良い選択だったかと思います。`}</p>
    <h1>{`NestJS のテストコードについて`}</h1>
    <p><strong parentName="p">{`山田`}</strong>{`: テストコードは基本書いているんですよね?`}</p>
    <p><strong parentName="p">{`熊本`}</strong>{`: はい、バックエンドは基本的に Jest で全部書いています。フロントエンドに関しては今まさに E2E テストをどのように実施していくかを試行錯誤しているところですね。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: NestJS でテストコード書くのに何か困ったこととかありましたか?`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 困っているというわけではないですが、Rails でほぼ必ず使われている `}<a parentName="p" {...{
        "href": "https://github.com/thoughtbot/factory_bot"
      }}>{`Factorybot`}</a>{` に相当する機能が欲しいですね…。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: 確かに `}<a parentName="p" {...{
        "href": "https://nodejs.org/ja/"
      }}>{`Node.js`}</a>{` だとそういったライブラリを見かけないですね…。`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 皆さんあんまり困ってないのかな?ということで今は簡易的なものを自作して使っています。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: OSS で作ったら需要があるかもしれませんね。`}</p>
    <h1>{`現状の NestJS での開発の課題感`}</h1>
    <p><strong parentName="p">{`山田`}</strong>{`: ここまで NestJS での開発について聞いてきましたが、プロダクト開発での課題は何かあったりしますか?`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 先ほども少し話をしたメンバーの習熟度が高くなったことによるコード改善の他には、NestJS というか Node.js が Rails に比べると定番ライブラリが少なめなのがあります。`}</p>
    <p>{`例えば、`}<a parentName="p" {...{
        "href": "https://sidekiq.org/"
      }}>{`Sidekiq`}</a>{` のようなライブラリって便利だと思うんですが、相当するライブラリでデファクトスタンダードというようなものが Node.js だと少ない気がしています。ライブラリが一杯あるのですが、デファクトまでいかないというものが多いという感想です。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: 確かに Node.js だと同じ目的のライブラリが複数あって機能がちょっとずつ違うという感じがありますね。`}</p>
    <p><strong parentName="p">{`牧野`}</strong>{`: あとは機能開発にリソースを取っているので、細かい部分でのライブラリのアップデートが遅れ気味というのが課題感としてあります。`}</p>
    <p>{`今のところはとてつもなく遅れているというわけではないのですが、積り積っていくとこういった部分が負債になっていくので、対応したいと思っています。`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: こういったものも含めてプロダクト開発を円滑に回すために、自分も含めた各メンバーのスキルを広げて深くしていかないといけないなというのもありますね。`}</p>
    <h1>{`チームへのオンボーディングについて`}</h1>
    <p><strong parentName="p">{`山田`}</strong>{`: さて、話が変わって現在アカデミーで一番最後にジョインしたのが徳永さんだと思いますが、オンボーディングなどはどのような感じだったんでしょうか。学生時代に NestJS はがっつり触っていたんでしたっけ?`}</p>
    <p><strong parentName="p">{`徳永`}</strong>{`: いえ、学生時代はチュートリアルをしたくらいで、深く使っていたということはなかったです。ですので、最初は全然プロダクトのコードが分からないというレベルで、ほとんど未経験でした。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: なるほど。ほぼ未経験で新卒研修を経て、アカデミーの開発チームにジョインして困った部分などはありましたか?`}</p>
    <p><strong parentName="p">{`徳永`}</strong>{`: TypeScript を使っているというのは凄く自分にとって良くて、言語でつまづくということはほとんどありませんでした。`}</p>
    <p>{`やはり`}<strong parentName="p">{`公式ドキュメントがすごく充実していますし、コミュニティの質の高い情報も多い`}</strong>{`ので。プロダクトに関しては過去の Pull Request や Issue 上のやり取りなどで、`}<strong parentName="p">{`どういう意図で実装されたものなのかを参照できたのが大きかった`}</strong>{`です。一方でバックエンドのデザインパターンなどは経験が少なく苦労した部分がありました。その部分はドキュメントで学習したり、周りのメンバーに直接質問しながらキャッチアップしていきました。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: 今年は入社後研修も TypeScript で書く割合が Ruby よりも多かったので、それがもし逆だったら、もっと苦労していたかもしれないですね。`}</p>
    <p><strong parentName="p">{`徳永`}</strong>{`: そうですね、その可能性は十分にあり得ます。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: あとアカデミーチームは火水木に勉強会を開催していますよね。ジョブメドレーの開発チームでも噂になっています。`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: これは全員で集まる勉強会というわけではなく、`}<strong parentName="p">{`月によって各自テーマを決めて火水木 1 時間ずつスキルアップのために時間を確保し、学んだことなどをアウトプットしていく`}</strong>{`という形式です。アウトプットしたものは、お互いに確認して知識を深めていこうというものですね。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: それは素敵な取り組みですね。あとは `}<a parentName="p" {...{
        "href": "https://www.figma.com/ja/"
      }}>{`Figma`}</a>{` で開発ドキュメントをまとめているのが印象的ですよね。`}</p>
    <p><strong parentName="p">{`牧野`}</strong>{`: 最初は岸田さんが率先して、開発した部分のドキュメントなんかを書いていたんですが、最近はチームで共有するようなものもほとんど Figma でまとめるようになっています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/62435510e868e7edd8e72ffdb49fc98d/b1ffc/note1104_010.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "98.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEQElEQVQ4y02UW28cNxKF5/8jz97H/IAFAmQFb5J14ljBws4mC1tCbM1o7hK7py/TV7K7ySZZ5wQzykMIFMCHwseqw1O1APl/a11x++69moaYeot0niT1VhKjbRIDElWfkteP79K7fJkmXZZqP6a3qzK9P1apn8ck2CqJwSUCJgsRZCLC+7s190nL6Mh5kmtkqqXX4FOf8fXmlvlQU/UFs6HmOtfMjdDbmvpwwzDlDNZwEUWeAdJNc9TdJN5S5kkkWEikyBgnOXapvNm9l9SUovpcMlOKA6U5p+L6RKLNRXwvEr0sRESJgHGmIBB29JhtRAyEHhrs1r9wX+3x4/5XnMwZqsuRmpL3Tw3++P0nxF5BSIgERIlYxCgKAD8+9/L9lw6b+gzVHPHUKjyfj8jaBIf6iDe7D8hMBdXnyEyDm08K+//+A9GcXoAAJAYsSCoB+FSNUurIZ1NxV+95Ae6yDf44fMZjfcC367dUuuRTlzEfKv7w2xavf/oPXK8uikFEIJcKs2ZQJ+15aL3MkTjWKfKxuia5eYYdLc5Ti7tyicq1KIYatTO433ZY3d/Be3fNjRcgiEU9evW83nP3/heZdQNGoOs6HA47qLIDr68Dq2TAKq3RmhFLVWNbDmgHC11sEf6COje9tDyfE+rN/2RSPzLkv7HoHG6XOb7/XOF2P+PYCtSp4CYrOQSg7iZW1ZlFaZCeGtS6vQIRHRYCKmcSTt2T6M0NpuQDhtHhqdR8PHX8eWu5roC6rbFRimYyOKsEXfrAU91jdEScWkj0ED9gEQUKs2V+Poje3cJnXzj1NXpt4CLRTQGdDSh0QKdHjNbCjCONFbbao1ZbhOIOeuiR5Q0WVV2rtu4IUkyxwvnzDe3QwIHQ1uMx67HNe2xyfdUzgDAzOVx8amZsdxn6agtxBiESC6NnVeQVJ9uJcS3K4ojRTKj6gd0wwwXCWA89hRegAHoGjSdnJ7jbezyUMwhedVxURaHUIeUmX8t5mJC3Dt0wYbAeuWmRmY5eCKHAS4CXCOsFUwDawaOtNUrt0RmDoa+wCCGoodXs6lLO3YS07nisFLwnPmUbfPPhn/h492+0XUItllN010ovMboIN00YxgnaaHircZ1lvhxppohDbVkOAcYR67PCze/fYvXpO5hZQ0fL0fUw6Qome0QwGYI1oMwo8hPKc3X5ZVFR5Orf0sx4OGkeG3fRCNvzHq/ff83t+leM8Oz9AOc1D7stn582rMuL3UrSNhx0AzNcbSPPl1kGELWLUuhZminIECgq38qnx7eSmkwGeNHixEYrpj3J1BdimpOMupHQZ4IwX6qSC7B4AZLWDjR2Zu/A2pFVumZ33lHbln2fsdMZp6kmw0wERwmOwf91v25PXBfsuyjYkHzI2mH1kHYr4+JqBpdJ+rB6c/tqdffzq9Xu7avVl399tTp9/G5FchlFlgL+LbAUwfJPnl3kOIrqHkcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Figma",
            "title": "Figma",
            "src": "/static/62435510e868e7edd8e72ffdb49fc98d/c1b63/note1104_010.png",
            "srcSet": ["/static/62435510e868e7edd8e72ffdb49fc98d/5a46d/note1104_010.png 300w", "/static/62435510e868e7edd8e72ffdb49fc98d/0a47e/note1104_010.png 600w", "/static/62435510e868e7edd8e72ffdb49fc98d/c1b63/note1104_010.png 1200w", "/static/62435510e868e7edd8e72ffdb49fc98d/b1ffc/note1104_010.png 1492w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`熊本`}</strong>{`: もちろん永続化しないといけないドキュメントは README なりコンフルエンスなりに書いていくのですが、ちょっとしたものを気軽に Figma にまとめられるのはすごく良いです。`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: `}<strong parentName="p">{`他の人がやっていることなんかも絶対に目につく`}</strong>{`ので、そういった意味でもドキュメントが散らばらなくて今は良く機能していると思っています。`}</p>
    <h1>{`現状の課題感とアカデミーの開発に向く人`}</h1>
    <p><strong parentName="p">{`山田`}</strong>{`: 最後になりますが、アカデミーに来てほしい人材はどういった方でしょうか?`}</p>
    <p><strong parentName="p">{`徳永`}</strong>{`: この`}<strong parentName="p">{`プロダクトにわくわくできる方に来てほしい`}</strong>{`と思います。福祉業界に明るい方なんかは大歓迎です!`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: なるほど。プロダクトのビジネス面の理解をして楽しんで開発できる方ですね。技術的な部分では何かありますか?`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 今まで TypeScript と NestJS を中心に話していましたが、実はこれらができる人というのは必須条件ではありません。どの言語でもフレームワークでも`}<strong parentName="p">{`ちゃんと Web 開発が分かっている方`}</strong>{`であればという感じです。`}</p>
    <p>{`他には`}<strong parentName="p">{`情報感度が高い人が良い`}</strong>{`と思います。言語化が難しいのですが、開発環境や言語、ライブラリなどできちんと最新の情報が取捨選択できる方がいると、チームがより活性化すると考えています。`}</p>
    <p>{`バックエンドでいうとベース部分などもきちんと手を入れることができて、なおかつ人に教えるのが好きな方が入ってもらえると、チームへの知識伝播などもより上手くいくと考えています。`}</p>
    <p>{`あるとより良いなという所だと、現在アプリは `}<a parentName="p" {...{
        "href": "https://reactnative.dev/"
      }}>{`React Native`}</a>{` で作られているんですが、React Native でのアプリ開発に強い方だと嬉しいですね。ユーザーに一番使われているのはアプリなので、さらにそのユーザビリティなどを高めるためには、アプリ開発に強い方に入ってもらえるとありがたいです。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: よりアカデミーというプロダクトをドライブさせてくれる方にぜひ来てほしいところですね。本日はありがとうございました!`}</p>
    <h1>{`おわりに`}</h1>
    <p>{`メドレーの開発チームの中でも野心的なアーキテクチャで開発をしているアカデミーチームについてお伝えしました。`}</p>
    <p>{`サービスを伸ばすために、適切に言語やフレームワークを選択していき、柔軟性を持って開発していってるんだなというのが伝わってきたインタビューでした。少しでも興味が出た方はぜひ、下のリンクからお話できればと思います!`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs"
      }}>{`https://www.medley.jp/jobs`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      